"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const components_react_1 = require("@iress/components-react");
const react_1 = require("react");
require("@iress/themes/global.css");
require("@iress/themes/build/css/iress-theme-light.css");
const TvShowResults_1 = require("./TvShowResults");
const ApiHook_1 = require("./helpers/ApiHook");
const DebounceHook_1 = require("./helpers/DebounceHook");
require("./index.css");
const DEBOUNCE_TIME = 500;
const PAGE_HEADING = 'TV Show Search';
/* eslint-disable  max-lines-per-function, react-perf/jsx-no-new-object-as-prop  -- max line rule does not apply to JSX and second one is for IDS IressCol options*/
const Page = () => {
    const [userSearchTerm, setUserSearchTerm] = (0, react_1.useState)('');
    const debouncedSearchTerm = (0, DebounceHook_1.useDebounce)(userSearchTerm, DEBOUNCE_TIME);
    const { data, isLoading, hasError, setUrl } = (0, ApiHook_1.useTVShowApi)();
    (0, react_1.useEffect)(() => {
        if (debouncedSearchTerm.trim() !== '' && debouncedSearchTerm !== 'null') {
            setUrl(`/api/shows?q=${debouncedSearchTerm}`);
        }
    }, [debouncedSearchTerm, setUrl]);
    const onInputClear = (0, react_1.useCallback)(() => {
        setUserSearchTerm('');
    }, [setUserSearchTerm]);
    const onInputChange = (0, react_1.useCallback)((event) => {
        setUserSearchTerm(event.currentTarget.value);
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: "search-container" }, { children: (0, jsx_runtime_1.jsx)(components_react_1.IressContainer, { children: (0, jsx_runtime_1.jsxs)(components_react_1.IressStack, Object.assign({ gutter: components_react_1.IressStack.Gutter.Xl }, { children: [(0, jsx_runtime_1.jsxs)(components_react_1.IressRow, Object.assign({ horizontalAlign: components_react_1.IressRow.HorizontalAlign.Center }, { children: [(0, jsx_runtime_1.jsx)(components_react_1.IressText, Object.assign({ element: components_react_1.IressText.Element.H1 }, { children: PAGE_HEADING })), (0, jsx_runtime_1.jsx)(components_react_1.IressCol, Object.assign({ span: {
                                    lg: components_react_1.IressCol.Span.Ten,
                                    md: components_react_1.IressCol.Span.Twelve,
                                } }, { children: (0, jsx_runtime_1.jsx)(components_react_1.IressInput, Object.assign({ clearable: true, onClear: onInputClear, onInput: onInputChange, placeholder: "Search", type: components_react_1.IressInput.Type.Search, watermark: true }, { children: (0, jsx_runtime_1.jsx)(components_react_1.IressIcon, { "data-testid": "search-icon", name: "search", screenreaderText: "Search", slot: components_react_1.IressInput.Slot.Prepend }) })) }))] })), (0, jsx_runtime_1.jsx)(TvShowResults_1.TvShowResults, { data: data, hasError: hasError, isLoading: isLoading })] })) }) })));
};
exports.Page = Page;
