"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageSkeleton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const components_react_1 = require("@iress/components-react");
const skeleton = (key = 1) => ((0, jsx_runtime_1.jsxs)(components_react_1.IressCard, Object.assign({ "data-testid": "tv-show-skeleton" }, { children: [(0, jsx_runtime_1.jsx)(components_react_1.IressSkeleton, { slot: components_react_1.IressCard.Slot.Header, textVariant: components_react_1.IressSkeleton.TextVariant.Heading4 }), (0, jsx_runtime_1.jsx)(components_react_1.IressSkeleton, { height: "295", mode: components_react_1.IressSkeleton.Mode.Rect, slot: components_react_1.IressCard.Slot.Supplemental, width: "210" }), (0, jsx_runtime_1.jsx)(components_react_1.IressSkeleton, { textVariant: components_react_1.IressSkeleton.TextVariant.Heading4 })] }), key));
const PageSkeleton = ({ count = 1 }) => {
    const skeletons = Array.from({ length: count }, (item, index) => skeleton(index));
    return ((0, jsx_runtime_1.jsx)(components_react_1.IressInline, Object.assign({ gutter: components_react_1.IressInline.Gutter.Lg, horizontalAlign: components_react_1.IressInline.HorizontalAlign.Center }, { children: skeletons })));
};
exports.PageSkeleton = PageSkeleton;
